import { observable, toJS } from 'mobx';
import { createContext } from 'react';
import L from 'leaflet';

export enum FocusStatus {
    NotFocused,
    Focused
}

class MapAnimationsStorage {
    @observable focusedlocation: any = [];
    @observable focusedZoom: number = 0;
    @observable fitBounds: any = {};
    @observable focusStatus: FocusStatus = FocusStatus.NotFocused;
    @observable focusedDetection: any = null;

    async focusOnDetection(detectionLog: any, detectors: any) {
        //console.log("DDETECTION: ", detectionLog)
        let detection = detectionLog[1];
        var points: any = [];
        //this.focusedDetection = detectionLog[1][detectionLog[1].length-1].drone.sn;
        //this.focusedDetection = detectionLog[1].drone.sn;

        var hasGPS = false;

        try {
            this.focusedDetection = detectionLog[1].drone.sn;

            for (var i = 0; i < detection.route.length; i++) {
                if (!(detection.route[i].droneLocation.lat === 0 && detection.route[i].droneLocation.lon === 0)) {
                    hasGPS = true;
                    points.push([detection.route[i].droneLocation.lat, detection.route[i].droneLocation.lon])
                }
            }

            if (!hasGPS) {
                //let detectorId = detectionLog[1].detector;
                var uniqueDetectorsId: any = [];
                var uniqueDetectors: any = [];
                if (detection.center && detection.center.lat && detection.center.lon) {
                    uniqueDetectors.push({
                        location: {
                            lat: detection.center.lat,
                            lon: detection.center.lon
                        }
                    })
                } else {
                    var uniqueDetectorsId: any = [];
                    detectionLog[1].route.forEach((reg: any) => { if (!uniqueDetectorsId.includes(reg.detector)) uniqueDetectorsId.push(reg.detector) })
                    detectors.forEach((detector: any) => { if (uniqueDetectorsId.indexOf(detector._id) !== -1) uniqueDetectors.push(toJS(detector)) })
                }
                var minLat = 9999;
                var minLon = 9999;
                var maxLat = -9999;
                var maxLon = -9999;
                uniqueDetectors.forEach((detector: any) => {
                    if ((detector.location.lat) < minLat) minLat = detector.location.lat;
                    if ((detector.location.lon) < minLon) minLon = detector.location.lon;
                    if ((detector.location.lat) > maxLat) maxLat = detector.location.lat;
                    if ((detector.location.lon) > maxLon) maxLon = detector.location.lon;
                });
                points.push([minLat - 0.01, minLon - 0.01])
                points.push([maxLat + 0.01, maxLon + 0.01])
            }
            this.fitBounds = L.latLngBounds(points);
            this.focusStatus = FocusStatus.Focused;
            setTimeout(() => this.focusStatus = FocusStatus.NotFocused, 300);

        } catch {
            //console.log(detectionLog[1])
            if (detectionLog[1].length > 0) {
                this.focusedDetection = detectionLog[1][detectionLog[1].length - 1].drone.sn;
            } else {
                this.focusedDetection = detectionLog[1].drone.sn;
            }

            for (var i = 0; i < detection.length; i++) {
                if (!(detection[i].droneLocation.lat === 0 && detection[i].droneLocation.lon === 0)) {
                    hasGPS = true;
                    points.push([detection[i].droneLocation.lat, detection[i].droneLocation.lon])
                }
            }
            if (hasGPS) {
                this.fitBounds = L.latLngBounds(points);
                this.focusStatus = FocusStatus.Focused;
                setTimeout(() => this.focusStatus = FocusStatus.NotFocused, 300);
            } else {
                let detectorId = detectionLog[1].detector;
            }
        }
    }

    async focusOnZone(zone: any) {
        if (zone.type === "CIRCLE") {
            let new_latitude1 = zone.center.lat + ((zone.radius + 50) / 6378000) * (180 / Math.PI);
            let new_longitude1 = zone.center.lon + ((zone.radius + 50) / 6378000) * (180 / Math.PI) / Math.cos(zone.center.lat * Math.PI / 180);
            let new_latitude2 = zone.center.lat - ((zone.radius + 50) / 6378000) * (180 / Math.PI);
            let new_longitude2 = zone.center.lon - ((zone.radius + 50) / 6378000) * (180 / Math.PI) / Math.cos(zone.center.lat * Math.PI / 180);
            this.fitBounds = new L.LatLngBounds([[new_latitude1, new_longitude1], [new_latitude2, new_longitude2]]);
            this.focusStatus = FocusStatus.Focused;
            setTimeout(() => this.focusStatus = FocusStatus.NotFocused, 1000);
        } else {
            this.fitBounds = L.latLngBounds(zone.perimeter);
            this.focusStatus = FocusStatus.Focused;
            setTimeout(() => this.focusStatus = FocusStatus.NotFocused, 1000);
        }
    }

    async focusOnCenter() {
        var lat = localStorage.getItem('mapCenterLat') ? Number(localStorage.getItem('mapCenterLat')) : 39.538391691513816
        var lon = localStorage.getItem('mapCenterLon') ? Number(localStorage.getItem('mapCenterLon')) : -0.06510968257644745
        this.fitBounds = L.latLngBounds([[lat, lon - 6], [lat, lon + 4], [lat - 4, lon], [lat + 4, lon]]);
        this.focusStatus = FocusStatus.Focused;
        setTimeout(() => this.focusStatus = FocusStatus.NotFocused, 1000);
    }
}

export const MapAnimationsStoreContext = createContext(new MapAnimationsStorage());